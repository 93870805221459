<template>
  <div>
    <plotly
      :height="450"
      :data="data[activeIndex]"
      @click="plotlyClick"
    ></plotly>
    <hr />
    <div  class="columns is-variable" >
      <div
          class="column"
          v-for="(graphical,index) in data"
          :key="index"
          :class="{ inactif: activeIndex!==index }"
      >
        <plotly
          style="cursor: pointer;"
          @click.native="displayGraphic(index)"
          :height="100"
          :staticPlot="true"
          :data="graphical"
          :onlyGraphic="true"
        ></plotly>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from '@/components/rresults/Plotly.vue'

export default {
  name: 'PlotlyList',
  props: {
    data: {
      type: Array,
      default: null
    }
  },
  components: {
    'plotly': Plotly
  },
  data: function () {
    return {
      activeIndex: 0
    }
  },
  methods: {
    displayGraphic (index) {
      this.activeIndex = index
    },
    plotlyClick (data) {
      this.$emit('click', data)
    }
  }
}
</script>

<style scoped>
.inactif {
  filter: grayscale(100%) opacity(30%);
}
</style>
